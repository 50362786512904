
import { defineComponent } from 'vue'

import ConfirmSection from '@/components/sections/ConfirmSection.vue'

export default defineComponent({
  name: 'Confirm',
  components: {
    ConfirmSection
  }
})
