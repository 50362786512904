
import { defineComponent } from 'vue'

import UserHeader from '@/components/molecules/UserHeader.vue'
import FooterUT from '@/components/molecules/FooterUT.vue'

export default defineComponent({
  name: 'HowItWorksSection',
  data () {
    return {
      translateData: {}
    }
  },
  components: {
    UserHeader,
    FooterUT
  },
  mounted () {
    this.translate()
  },
  methods: {
    translate (data) {
      data = []
      data.push('policy_title', 'i_agree_button', 'policy_text')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }
})
